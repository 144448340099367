.modal {
  &.show .modal-dialog {
    max-width: 420px;
    opacity: 0;

    // @media (max-width: 1600px) {
    //   max-width: 880px;
    //   margin-top: 55px;
    // }
    @media (max-width: 1366px) {
      // max-width: 880px;
      max-width: 420px;
      // margin-top: 34px;
    }

    .modal-body {
      @media (max-width: 1600px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
      }
    }

    .modal-header {
      @media (max-width: 1600px) {
        // padding-bottom: 0;
      }

      .close {
        img {
          height: 14px;
        }
      }
    }
  }

  &.auth-modals .modal-dialog {
    opacity: 1;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;
    // @media (max-width: 1600px) {
    max-width: 420px;
    height: 100%;
    margin: auto;

    // }
    .modal-content {
      background: transparent;
      background: transparent linear-gradient(41deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border-radius: 20px;
      // padding: 48px 60px;
      border: none;
      opacity: 0.97;
      // @media (max-width: 1600px) {
      padding: 18px 18px;

      // }
      @media (max-width: 767px) {
        padding: 15px 15px;
      }

      .btn {
        font-size: 18px;
        font-weight: 400;
        padding: 14px 40px;
        margin-bottom: 15px !important;
        // @media (max-width: 1366px) {
        //   font-size: 17px;

        // }
        &~div {
          // border-top: 1px solid rgba(112, 112, 112, 0.3);
          padding-top: 6px;
          padding-bottom: 0px;
          // margin-bottom: 25px;
        }
      }

      .form-group {
        padding-left: 31px;
        padding-top: 8px;
        padding-right: 20px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
        }
      }

      .form-control {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        font-weight: 300;
        height: auto;
        line-height: 38px;
        padding-top: 10px;
        color: #000;

        @media (max-width: 1600px) {
          font-size: 16px;
          line-height: 28px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        option {
          padding-left: 10px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: map-get($theme-colors, primary);
        margin-bottom: 0px;

        @media (max-width: 1366px) {
          font-size: 15px;
        }
      }

      .modal-header {
        border: 0;
        padding-top: 0px;
        padding-right: 0px;
      }

      .student-login-box {
        max-width: 530px;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin: 0 auto;
        overflow: hidden;

        @media (max-width: 767px) {
          max-width: 670px;
        }

        .student-login {
          border-bottom: 1px solid rgba($color: #707070, $alpha: 0.3);
          padding-bottom: 25px;
          margin-bottom: 25px;

          @media (max-width: 1366px) {
            padding-bottom: 15px;
            margin-bottom: 30px;
          }

          .login-box {
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            border-radius: 20px;
            width: 100%;

            @media (max-width: 1600px) {
              border-radius: 20px;
            }
          }

          h5 {
            font-weight: 400;
          }
        }

        .reg-account {
          max-width: 400px;
          margin: 0 auto;

          @media (max-width: 767px) {
            max-width: 670px;
          }

          h5 {
            margin-bottom: 20px;
            font-weight: 300;

            @media (max-width: 1366px) {
              margin-bottom: 20px;
            }
          }

          .btn-social {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-register {
            color: map-get($theme-colors, success);
            min-width: 132px;
            padding: 5px 10px;
          }

          .btn-social {
            &:hover {
              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
      }

      // Registor modal css
      .register {
        .student-login {
          border: 0;
        }
      }

      // continue registor modal css
      .cont-registration {
        padding-bottom: 10px;

        // @media (max-width: 1366px) {
        //   padding-bottom: 10px;
        // }
        h3 {
          margin-bottom: 58px;

          // @media (max-width: 1366px) {
          //   margin-bottom: 60px;
          // }
          @media (max-width: 767px) {
            margin-bottom: 27px;
          }
        }

        .otp-text {
          padding-bottom: 0px;
          margin-bottom: 0;
          padding-left: 15px;
          font-weight: 300;
          font-size: 14px;
          margin-left: 17px;
        }

        .send-otp {
          padding-left: 15px;
          font-weight: 300;

          svg {
            path {
              fill: map-get($theme-colors, success);
            }
          }
        }
      }

      // Done registor modal css
      .done-registration {
        h3 {
          // margin-bottom: 15px;
          margin-bottom: 6px;
        }

        .student-login {
          border: 0;
        }
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
        // @media (max-width: 1366px) {
        //   margin-bottom: 15px;
        // }
      }
    }
  }

  &.confirm-modals .modal-dialog {
    opacity: 1;
    max-width: 600px;
    height: 100%;
    margin-bottom: 0px;
    // top: 34%;
    margin-top: 0px !important;
    vertical-align: middle;
    margin: auto;

    // -webkit-box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.3);
    // -moz-box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.3);
    // box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.3);
    @media (max-width: 1366px) {
      max-width: 350px;
    }

    .modal-content {
      background: linear-gradient(41deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      // border-radius: 40px;
      padding: 20px 25px;
      border: none;
      opacity: 0.97;

      .modal-header {
        border-bottom: none;
      }

      .modal-footer {
        border-top: none;
      }

      @media (max-width: 767px) {
        padding: 15px 0px;
      }

      .btn {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .modal-backdrop {
      background-color: #ffffff !important;
      opacity: 0 !important;
    }
  }

  &.enroll-modals .modal-dialog {
    opacity: 1;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;

    .modal-content {
      background: transparent linear-gradient(51deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 20px;
      padding: 18px;
      padding-bottom: 60px;
      // padding-bottom: 24px;
      // min-height: 450px;


      .modal-header {
        border: 0;
        padding-bottom: 3px !important;
      }

      .btn {
        font-size: 0.875rem;
      }

      .enroll-box {
        h3 {
          margin-bottom: 65px;
        }

        .checkout {
          max-width: 380px;
          width: 100%;
          margin: 0 auto;

          .btn-custom {
            min-width: 120px;
            padding: 8px 15px;
          }

          h6 {
            margin-bottom: 40px;
          }
        }

        .course-name {
          font-weight: 500;
        }

        .subject-name {
          font-size: 12px;
          padding-right: 15px;
          line-height: 16px;
          display: inline-block;
          color: #475677;
          word-break: break-all;
        }

        .selected-course {
          border-right: 1px solid rgba($color: #707070, $alpha: 0.2);
          min-height: 310px;

          .scroll {
            max-height: 260px;
            overflow-y: auto;
          }

          .course-names {
            margin-bottom: 45px;

            .course-name-inner {
              width: 88%;
            }
          }

          @media (max-width: 992px) {
            border-right: none;
            border-bottom: 1px solid rgba($color: #707070, $alpha: 0.2);
            min-height: auto;
            margin-bottom: 30px;
          }
        }

        .select-payment-methode {
          .card-number {
            margin-top: 15px;
            margin-bottom: 20px;

            .form-control {
              width: 93px;
              margin-right: 10px;
              border: 0;
              border-radius: 10px;

              @media (max-width: 1600px) {
                width: 78px;
                margin-right: 8px;
              }

              @media (max-width:650px) {
                width: 66px;
              }
            }
          }
        }
      }
    }

    .enrolled-success-scroll {
      max-height: 260px;
      overflow-y: auto;
    }
  }

  &.teacher-session-modal .modal-dialog {
    opacity: 1;
    max-width: 704px;
    top: 34%;
    margin-top: 0px !important;

    .modal-content {
      box-shadow: 0px 2px 10px #00000029;
      background-color: #e5e5e5;
      border: 0;
      border-radius: 20px;
      overflow: hidden;

      .modal-body {
        // padding: 1.4rem;
        padding: 40px;
        padding-top: 0px;

        .content-editor {
          background-color: rgba($color: #3d4057, $alpha: 0.05);
          min-height: 15px;
          padding: 1.4rem;
          font-size: 14px;
          font-weight: 400;
          border-radius: 20px;
          margin-bottom: 100px;
        }
      }

      .modal-header {
        // padding: 1.4rem 1.4rem;
        padding: 40px 45px;
        // padding-bottom: 1.4rem;
        border: 0;

        .modal-title,
        .close {
          // color: #ffffff;
          // opacity: 1;
          font-weight: 600;
          font-size: 18px;
        }

        .close {
          position: absolute;
          top: 18px;
          right: 20px;
        }
      }

      .modal-footer {
        justify-content: flex-start;
        padding: 1.4rem;
        border: 0;

        .btn-send {
          min-width: 234px;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }

  &.notes-modal .modal-dialog {
    opacity: 1;
    max-width: 500px;
    top: 34%;
    margin-top: 0px !important;

    .modal-content {
      box-shadow: 0px 2px 10px #00000029;
      border: 0;
      border-radius: 22px;
      overflow: hidden;

      .modal-body {
        padding: 1.4rem;

        .content-editor {
          background-color: rgba($color: #3d4057, $alpha: 0.05);
          min-height: 15px;
          padding: 1.4rem;
          border-radius: 22px;
        }
      }

      .modal-header {
        padding: 1.4rem 1.4rem;
        padding-bottom: 1.4rem;
        border: 0;

        .modal-title,
        .close {
          color: #ffffff;
          opacity: 1;
          font-weight: normal;
        }
      }

      .modal-footer {
        justify-content: flex-start;
        padding: 1.4rem;
        border: 0;

        .btn-send {
          min-width: 234px;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }

  &.video-modal .modal-dialog {
    opacity: 1;
    // top: 28%;
    max-width: 800px;
    margin-top: 0px !important;

    .modal-content {
      // box-shadow: 0px 2px 10px #00000029;
      border: 0;
      border-radius: 22px;
      overflow: hidden;
      position: relative;

      .modal-header {
        padding: 1.4rem 1.4rem;
        padding-bottom: 1.4rem;
        border: 0;
        width: 100%;
        color: white;
        position: absolute;
        background-color: transparent;
        z-index: 1000;

        .modal-title,
        .close {
          z-index: 1000;
          color: #ffffff;
          // font-size: 18px;
          // font-weight: 600;
          opacity: 2;
        }
      }

      .modal-footer {
        justify-content: flex-start;
        padding: 1.4rem;
        border: 0;

        .btn-send {
          min-width: 234px;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }

  &.syllabus-modal .modal-dialog {
    opacity: 1;
    top: 34;
    max-width: 420px;
    // animation: fade-in 0.3s;
    // -moz-animation: fade-in 0.3s;
    transition: opacity 0.2s ease 0s;
    // -moz-transition: opacity 0.2 ease 0s;

    .modal-content {
      box-shadow: 0px 2px 10px #00000029;
      border: 0;
      border-radius: 20px;
      overflow: hidden;
      background-color: #e5e5e5;

      // min-height: 397px;
      .modal-body {
        // padding: 1.4rem;
        padding: 40px;
        padding-top: 0px;

        .btn-download {
          padding: 12px 23px;
          // margin-top: 32px;
          min-width: 169px;

          &:hover,
          &:focus {
            color: #00ae4d !important;
          }
        }

        .download-pane {
          margin-top: 32px;
        }
      }

      .modal-header {
        // padding: 1.4rem 1.4rem;
        padding: 40px 45px;
        // padding-bottom: 1.4rem;
        border: 0;

        .modal-title,
        .close {
          // color: #ffffff;
          font-size: 18px;
          font-weight: 600;
          // opacity: 1;
        }

        .close {
          position: absolute;
          top: 18px;
          right: 20px;
        }
      }
    }
  }

  &.session-notes-modal .modal-dialog {
    max-width: 420px;
    // box-shadow: 0px 2px 10px #00000029;
    // margin-top: 0px !important;
    // top: 28%;
    opacity: 1;
    border: 0;
    border-radius: 28px;
    overflow: hidden;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;

    .form-label-group {
      background-color: #ffffff;
      border: 1px solid #cfcfcf;
      width: 100%;

      @media (max-width: 1600px) {
        border-radius: 20px;
      }

      &:not(:last-child) {
        border-bottom: none;
      }
    }

    .form-control {
      border: none;
      background-color: transparent;
      font-size: 16px;
      padding: 0;
      font-weight: 300;
      height: auto;
      line-height: 38px;

      @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 28px;
      }

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }

      option {
        padding-left: 10px;
      }
    }

    .modal-content {
      box-shadow: 0px 2px 10px #00000029;
      border: 0;
      border-radius: 20px;
      overflow: hidden;
      background-color: #e5e5e5;

      .modal-header {
        padding-bottom: 1.4rem;
        padding-left: 40px;

        .close {
          opacity: 0.5;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }

      .modal-body {
        padding: 1.4rem;
        padding-left: 40px;
        padding-right: 40px;

        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 40px;
        }

        .upload-notes {
          // border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
          padding-bottom: 20px;
        }

        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;

          input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          .btn-outline-primary {
            padding: 12px 15px;
            min-width: 200px;
          }
        }
      }
    }
  }

  &.feedback-modal .modal-dialog {
    max-width: 704px;
    // box-shadow: 0px 2px 10px #00000029;
    opacity: 1;
    border: 0;
    top: 0 !important;
    height: auto !important;
    margin-top: 0px !important;
    border-radius: 22px;
    overflow: hidden;
    animation: fade-in 0.3s;
    -moz-animation: fade-in 0.3s;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);

    @media (min-width: 576px) {
      min-height: calc(100% - 3.5rem);
    }

    .modal-content {
      box-shadow: 0px 2px 10px #00000029;
      border: 0;
      border-radius: 20px;
      overflow: hidden;
      background-color: #e5e5e5;

      .modal-header {
        // padding-bottom: 1.4rem;
        padding-left: 40px;
      }

      .modal-body {
        // padding: 1.4rem;
        padding: 40px;

        h5 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 38px;
        }

        .feedback-text {
          padding: 10px;
          border-radius: 20px;
          background-color: #fff;
          margin-bottom: 35px;

          .feedback-text {
            margin-bottom: 0px;
          }
        }

        .upload-notes {
          border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
          padding-bottom: 20px;
        }

        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;
          width: 100%;

          input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          .btn-outline-primary {
            padding: 12px 15px;
            min-width: 200px;
          }
        }
      }
    }
  }

  &.homework-modal .modal-dialog {
    opacity: 1;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;
    max-width: 420px;
    height: 100%;

    .modal-content {
      background: transparent linear-gradient(51deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 20px;
      padding: 18px;

      .form-group {
        padding-left: 24px;
        padding-top: 8px;
        padding-bottom: 8px;

        // padding-right: 20px;
        .btn.btn-outline-primary {
          border-color: #008a00;
          color: #008a00;
          opacity: 0.8;
          padding: 8px 12px !important;
          min-width: auto;

          &:hover {
            background: #00AE4D;
          }
        }

        .btn {
          background-color: #fff;
          border-color: #3d4057;
          color: #3d4057;
          padding: 10px 16px;
          opacity: 0.5;
        }

        &:not(:last-child) {
          // border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
        }
      }

      .form-control {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        font-weight: 300;
        height: auto;
        line-height: 38px;
        position: relative;
        z-index: 1;

        @media (max-width: 1600px) {
          font-size: 16px;
          line-height: 28px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        option {
          padding-left: 10px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: map-get($theme-colors, primary);
        margin-bottom: 0px;

        @media (max-width: 1366px) {
          font-size: 15px;
        }
      }

      .modal-header {
        border: 0;
        padding-top: 0px;
        padding-right: 0px;

        .close {
          opacity: 0.5;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }

      .student-login-box {
        max-width: 530px;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin: 0 auto;

        @media (max-width: 767px) {
          max-width: 670px;
        }

        .student-login {
          border-bottom: none;
          padding-bottom: 0px;
          margin-bottom: 0px;

          // @media (max-width: 1366px) {
          //   padding-bottom: 15px;
          //   margin-bottom: 30px;
          // }
          .login-box {
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            border-radius: 20px;
            width: 100%;

            .form-label-group {
              padding: 8px 25px 14px 25px !important;
            }

            @media (max-width: 1600px) {
              border-radius: 20px;
            }
          }

          h5 {
            font-weight: 400;
          }
        }
      }

      .modal-body {

        // padding: 1rem;
        // padding-left: 40px;
        // padding-right: 40px;
        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 40px;
        }

        // .student-login-box {
        //   padding-right: 10px;
        //   padding-left: 10px;
        // }
        .upload-notes {
          // border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
          padding-bottom: 20px;
        }

        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;

          input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          .btn-outline-primary {
            padding: 12px 15px;
            min-width: 200px;
          }
        }
      }

      .file-name {
        display: flex;

        label {
          width: 80%;
          word-break: break-all;
        }
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
        // @media (max-width: 1366px) {
        //   margin-bottom: 15px;
        // }
      }
    }
  }

  &.add-homework-modal .modal-dialog {
    opacity: 1 !important;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;
    // @media (max-width: 1600px) {
    max-width: 420px;

    // }
    .modal-content {
      background: transparent;
      background: transparent linear-gradient(41deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border-radius: 20px;
      // padding: 48px 60px;
      border: none;
      opacity: 0.97;
      // @media (max-width: 1600px) {
      padding: 18px 18px;

      // }
      @media (max-width: 767px) {
        padding: 15px 15px;
      }

      .btn {
        font-size: 18px;
        font-weight: 400;
        padding: 14px 40px;
        margin-bottom: 25px !important;
        // @media (max-width: 1366px) {
        //   font-size: 17px;

        // }
        &~div {
          border-top: 1px solid rgba(112, 112, 112, 0.3);
          padding-top: 26px;
          padding-bottom: 0px;
          // margin-bottom: 25px;
        }
      }

      .form-group {
        padding-left: 31px;
        padding-top: 8px;
        padding-right: 20px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
        }
      }

      .form-control {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        font-weight: 300;
        height: auto;
        line-height: 38px;

        @media (max-width: 1600px) {
          font-size: 16px;
          line-height: 28px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        option {
          padding-left: 10px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: map-get($theme-colors, primary);
        margin-bottom: 0px;

        @media (max-width: 1366px) {
          font-size: 15px;
        }
      }

      .modal-header {
        border: 0;
        padding-top: 0px;
        padding-right: 0px;
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
        // @media (max-width: 1366px) {
        //   margin-bottom: 15px;
        // }
      }
    }
  }

  &.test-modal .modal-dialog {
    border: 0;
    // top: 15%;
    height: 100%;
    max-width: 80%;
    // top: 0px;
    margin: 0px auto;
    opacity: 1;
    margin-top: 0px !important;
    border-radius: 22px;
    overflow: hidden;
    animation: fade-in-test 0.3s;
    -moz-animation: fade-in-test 0.3s;

    .modal-content {
      background: transparent;
      background: transparent linear-gradient(41deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border-radius: 40px;
      padding: 48px 60px;
      border: none;
      opacity: 0.97;



      @media (max-width: 767px) {
        padding: 15px 0px;
      }
    }

    .add-new-test {
      form {
        input {
          font-size: 20px !important;
        }
      }

    }

  }

  &.rating-modal .modal-dialog {
    opacity: 1;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;
    max-width: 420px;
    height: 100%;

    .modal-content {
      background: transparent linear-gradient(51deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 20px;
      padding: 18px;

      .form-group {
        padding-left: 31px;
        padding-top: 8px;
        padding-bottom: 8px;

        .btn {
          background-color: #fff;
          border-color: #3d4057;
          color: #3d4057;
          padding: 10px 16px;
          opacity: 0.5;
        }

        &:not(:last-child) {
          // border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
        }
      }

      .form-control {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        font-weight: 300;
        height: auto;
        line-height: 38px;
        position: relative;
        z-index: 1;

        @media (max-width: 1600px) {
          font-size: 16px;
          line-height: 28px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        option {
          padding-left: 10px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: map-get($theme-colors, primary);
        margin-bottom: 0px;

        @media (max-width: 1366px) {
          font-size: 15px;
        }
      }

      .modal-header {
        border: 0;
        padding-top: 0px;
        padding-right: 0px;

        .close {
          opacity: 0.5;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }

      .student-login-box {
        max-width: 530px;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin: 0 auto;

        @media (max-width: 767px) {
          max-width: 670px;
        }

        .student-login {
          border-bottom: none;
          padding-bottom: 0px;
          margin-bottom: 0px;

          // @media (max-width: 1366px) {
          //   padding-bottom: 15px;
          //   margin-bottom: 30px;
          // }
          .login-box {
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            border-radius: 20px;
            width: 100%;

            .form-label-group {
              padding: 8px 25px 14px 25px !important;
            }

            @media (max-width: 1600px) {
              border-radius: 20px;
            }
          }

          h5 {
            font-weight: 400;
          }
        }
      }

      .modal-body {

        // padding: 1rem;
        // padding-left: 40px;
        // padding-right: 40px;
        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 40px;
        }

        // .student-login-box {
        //   padding-right: 10px;
        //   padding-left: 10px;
        // }
        .upload-notes {
          // border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
          padding-bottom: 20px;
        }

        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;

          input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          .btn-outline-primary {
            padding: 12px 15px;
            min-width: 200px;
          }
        }
      }

      .file-name {
        display: flex;

        label {
          width: 80%;
          word-break: break-all;
        }
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
        // @media (max-width: 1366px) {
        //   margin-bottom: 15px;
        // }
      }
    }
  }

  &.test-modal .modal-dialog {
    opacity: 1 !important;
    animation: slide-left 0.5s;
    -moz-animation: slide-left 0.5s;

    // @media (max-width: 1600px) {
    // max-width: 420px;
    // }
    .modal-content {
      background: transparent;
      background: transparent linear-gradient(41deg, #e5e5e5 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
      border-radius: 20px;
      // padding: 48px 60px;
      border: none;
      opacity: 0.97;
      // @media (max-width: 1600px) {
      padding: 18px 18px;

      // }
      @media (max-width: 767px) {
        padding: 15px 15px;
      }

      .btn {
        font-size: 18px;
        font-weight: 400;
        padding: 9px 40px;
        // @media (max-width: 1366px) {
        //   font-size: 17px;

        // }
        &~div {
          border-top: 1px solid rgba(112, 112, 112, 0.3);
          padding-top: 26px;
          padding-bottom: 0px;
          // margin-bottom: 25px;
        }
      }

      .form-group {
        // padding-left: 31px;
        // padding-top: 8px;
        // padding-right: 20px;
        // &:not(:last-child) {
        //   border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.2);
        // }
      }

      .form-control {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        font-weight: 300;
        height: auto;
        line-height: 38px;

        @media (max-width: 1600px) {
          font-size: 16px;
          line-height: 28px;
        }

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }

        option {
          padding-left: 10px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: map-get($theme-colors, primary) !important;
        margin-bottom: 0px;
        pointer-events: none;

        @media (max-width: 1366px) {
          font-size: 15px;
        }
      }

      .modal-header {
        border: 0;
        padding-top: 0px;
        padding-right: 0px;
      }

      h3 {
        letter-spacing: 0.49px;
        margin-bottom: 27px;
        font-size: 18px;
        // @media (max-width: 1366px) {
        //   margin-bottom: 15px;
        // }
      }

      .modal-body {
        // max-height: 95vh;
        // overflow-x: hidden;
        // overflow-y: auto;

        &::-webkit-scrollbar {
          height: 5px;
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }

  .modal-body {
    max-height: 85vh !important;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.modal-close {
  transition: none;
  transform: none;
  animation: slide-right 0.5s;
  -moz-animation: slide-right 0.5s;
  -ms-animation: slide-right 0.5s;
}

.modal-close-fade {
  transition: none;
  transform: none;
  animation: fade-out 0.3s;
  -moz-animation: fade-out 0.3s;
  -ms-animation: fade-out 0.3s;
}

@keyframes slide-left {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    right: 0%;
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    left: 0%;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
}

.modal-backdrop {
  opacity: 0.9 !important;
  background-color: #3d4057 !important;
}

.confirm-modal-backdrop {
  opacity: 0.5 !important;
  background-color: #3d4057 !important;
}

@keyframes fade-in {
  0% {
    top: 27%;
    opacity: 0;
  }

  100% {
    top: 29%;
    opacity: 1;
  }
}

@keyframes fade-in-test {
  0% {
    top: 12%;
    opacity: 0;
  }

  100% {
    top: 15%;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    top: 0%;
    opacity: 1;
  }

  100% {
    top: -4%;
    opacity: 0;
  }
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  // height: calc(100% - 75px);
}

.max-width-modal {
  .modal-dialog {
    max-width: 860px !important;

    @media (max-width: 992px) {
      max-width: 450px !important;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .test-modal {
    .modal-dialog {
      min-width: 80% !important;
    }
  }
}

@media (max-width: 480px) {
  .test-modal {
    .modal-body {
      padding: 0px;
    }

    .modal-dialog {
      max-width: 95% !important;

      .add-new-test {
        border-radius: 18px;
      }
      .select-box {
        margin-right: 5px !important;
        width: 100%;
        select{
          width: 100% !important;
        }
      }
      .test-detail{
        width: 100%;
        margin: 0px 0px 15px 0px ;
        border-bottom-left-radius: 18px !important;
        border-bottom-right-radius: 18px !important; 
      }
      .submit-pane{
        .btn-pane{
          .btn{
            width: 96%;
          }
        }
      }
    }
  }
  .my-course-details-box{
    .course-details{
      .courses{
        .subject-info{
          .tab-content{
            padding: 0px 10px !important;
          }
        } 
      } 
    } 
  }
}


.test-detail .form-label-group {
  padding-left: 0px;

  label {
    left: 0;
    font-size: 18px !important;
  }
}


/****  floating-Lable style start ****/
.floating-label {
  position: relative;
  margin-bottom: 20px;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 4px 4px;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  /* border-bottom:1px solid #757575; */
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  /* border-bottom:2px solid #5264AE;  */
}

.label-grade {
  color: rgba(61, 64, 87, 0.8) !important;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 30px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  top: 2px;
  font-size: 14px;
  color: #00AE4D !important;
}

.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  top: 2px !important;
  font-size: 14px;
  color: #00AE4D !important;
}

/* active state */
.floating-input:focus~.bar:before,
.floating-input:focus~.bar:after,
.floating-select:focus~.bar:before,
.floating-select:focus~.bar:after {
  width: 50%;

  /* active state */
  .floating-input:focus~.highlight,
  .floating-select:focus~.highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* animation */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #00AE4D;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @-moz-keyframes inputHighlighter {
    from {
      background: #00AE4D;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @keyframes inputHighlighter {
    from {
      background: #00AE4D;
    }

    to {
      width: 0;
      background: transparent;
    }
  }
}

.done-registration.last-step {
  .form-group {
    padding-left: 16px !important;
  }
}

.done-registration {
  .upload-btn-wrapper {
    img {
      width: 16px;
      margin-right: 10px;
    }

    img.cancel-icon {
      width: 11px;
      margin-top: 2px;
      margin-right: 0;
    }
  }
}
.modal-backdrop {
  z-index: 1040 !important;
}
.modal {
  z-index: 1050 !important;
}