:root {
  font-size: 14px;
  // @media (max-width: 1366px) {
  //   font-size: 14px;
  // }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@font-face {
  font-family: "debussy";
  src: url('../src/assets/font/debussy.ttf') ;
}
//video player styles
// @import '~ngx-videogular/fonts/videogular';
//text editor quill
/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url("../node_modules/poppins-font/index.css");

// ngx toastr styling
// @import "~ngx-toastr/toastr.css";
@import 'ngx-toastr/toastr';

// Overriting bootstrap variables and styles
@import "./helpers/mixins";
@import "./helpers/variables";
@import "./helpers/buttons";

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// importing general styling
@import "./helpers/general";
@import "./helpers/typography";

@import "./helpers/modal";

@import "~ng-pick-datetime/assets/style/picker.min.css";

@import "./helpers/fullcalendar-custom";

// START OF OWL CAROUSEL //
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.card{
  overflow: visible !important;
}
.notification-slider{
  .owl-carousel{
    width: 205px;
    height: 25px;
    position: relative;
    .owl-nav{
      position: absolute;
      top: -16px;
      right: 0px;
      margin-top: 0px;
      .owl-prev,
      .owl-next{
        border-radius: unset;
        padding: 0px;
        color: #000;
        background-color: transparent;
        margin: 0px;
        font-size: 18px;
        margin-left: 6px;
      }
    }
  }
} 
// END OF OWL CAROUSEL //

// @import './helpers/enroll-modal';
.ck-content {
  border: none !important;
  min-height: 100px !important;
}

.ck-toolbar {
  border-top: 1px solid #e4dada !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

body {
  padding-top: 75px;

  @media (max-width: 420px) {
    padding-top: 57px;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1052 !important;
}
// .ck-editor-parent-test-component {
//   //TO SHOW CK EDITOR TOOLBAR UNDER CONTAINER
//   .ck.ck-editor__main > .ck-editor__editable {
//     min-height: 100px;
//   }

//   .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar,
//   .ck-sticky-panel__content {
//     border-top: none;
//     border-left: none;
//     border-right: none;
//     padding-top: 0px;
//     float: right;
//     .ck-reset_all{
//       height: 25px !important;
//     }
//     border-bottom: 1px solid #999494 !important;
//     .ck.ck-toolbar__items {
//     border-bottom: none !important;
//     }
//   }
// }
.ck-editor-parent {
  //TO SHOW CK EDITOR TOOLBAR UNDER CONTAINER
  .ck.ck-reset.ck-editor {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 100px;
  }

  .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck-sticky-panel__content {
    height: 54px;
  }
}

bs-modal-backdrop {
  z-index: 1118 !important;
}

ngb-tooltip-window {
  z-index: 1200 !important;
}

// .swiper-container {
//   overflow: initial !important;
// }

svg.fa-file-pdf path {
  fill: #bf3c3c !important;
}

svg.fa-file-word path {
  fill: #243c80 !important;
}

svg.fa-file-powerpoint path {
  fill: #cc4523 !important;
}

.pagination-pane {
  ul.ngx-pagination {
    padding-left: 0px !important;
    margin-bottom: 1.2rem;
    margin-top: 1rem;

    li {
      min-width: 36px;
      width: 36px;
      height: 36px;
      background: #b1b2bc;
      border-radius: 100%;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
      overflow: hidden;

      &:first-child,
      &:last-child {
        background-color: transparent;
        width: auto;
        color: #212529;

        a {
          &:hover {
            background: transparent;
            color: #212529;
          }
        }
      }
    }

    li.current {
      background: #212529;
    }

    li a {
      &:hover {
        background: #212529;
        color: #ffffff;
      }

      &:focus {
        background: inherit;
      }
    }
  }
}

/* For hide eye icon on paswword field for Edge Browser */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* Conatiner min height for footer */
.student-dashboard-courses-section,
.my-courses-box,
.my-course-details-box,
.my-schedule-box,
.study-material-section,
.teacher-session-section,
.teacher-class-box,
.content-wrapper {
  min-height: calc(100vh - 538px);

  @media (max-width: 1366px) {
    min-height: calc(100vh - 520px);
  }

  @media (max-width: 992px) {
    min-height: calc(100vh - 674px);
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - 704px);
  }
}

/* Simple Content Pages */
.content-wrapper {
  .content {
    padding-top: 79px;
    padding-bottom: 40px;
  }
}

.owl-dt-timer-content {
  .owl-dt-timer-input {
    //disable keyboard input on ng pick date time
    pointer-events: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

.teacher-final-step {
  .form-label-group.label-special {
    padding-left: 30px !important;

    label {
      pointer-events: none;
      left: 29px !important;
    }
  }
}

app-notification {
  width: 100% !important;
}

.availablity-info {
  .multiselect-dropdown {
    .dropdown-btn {
      padding-right: 15px !important;

      .selected-item {
        display: flex;
        align-items: center;
      }
    }
  }
}

.ck.ck-editor__top.ck-reset_all {
  position: relative !important;
  height: 60px !important;
  .ck.ck-sticky-panel__content {
    position: absolute;
    width: 100% !important;
  }
}

.my-profile {
  .doc-profile {
    .download-pane {
      min-height: 215px;
      padding-top: 10%;
    }
  }
}

.menue-expended {
  overflow: hidden;
  .menu {
    overflow: auto;
    padding-bottom: 60px;
  }
}
