// .modal-backdrop {
//   background-color: #ffffff !important;
//   opacity: 0 !important;
// }
// .general-modal-backdrop{
//   opacity: 0 !important;
// }
header {
  .active {
    >.nav-link {
      color: map-get($theme-colors, success);
    }
  }
}

button[aria-label="Close"]:focus {
  outline: none;
}

input:-webkit-autofill {
  // background-color: rgb(255, 255, 255) !important;
  background-color: transparent !important;
  // box-shadow: 0 0 0 50px white inset;
  // -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  // // -webkit-text-fill-color: #333;
  box-shadow: 0 0 0 30px white inset !important;
}

.cursor-pointer {
  cursor: pointer !important;
  font-size: 12px;
}

// Ofcanvas menu styling

.offcanvas-collapse {
  @media (max-width: 1199px) {
    position: fixed;
    top: 74px;
    bottom: 0;
    // left: 100%;
    right: -100%;
    width: 50%;
    padding-top: 20px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    visibility: hidden;
    overflow-y: auto;
    background-color: white;
    transition-duration: 0.2s;
    transition-property: right, visibility;
    transition-timing-function: linear;
    z-index: 9999;
    box-shadow: -5px 8px 10px rgba($color: #3d4057, $alpha: 0.3);
  }

  @media (max-width: 767px) {
    width: 70%;
  }

  @media (max-width: 576px) {
    width: 80%;
  }

  .nav-item {
    .nav-link {
      @media (max-width: 1199px) {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        border-bottom: 1px solid rgba($color: #3d4057, $alpha: 0.1) !important;
      }
    }

    .btn-add-course {
      @media (max-width: 1199px) {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        margin-top: 15px;
      }
    }
  }

  .tabs-box {
    .nav-pills {
      a {
        padding: 0 !important;
        padding-bottom: 8px !important;
        padding-left: 30px !important;
        border-bottom: 0 !important;
      }
    }
  }

  .close-offcanvas-menu {
    display: none;

    @media (max-width: 1199px) {
      display: block;
    }
  }
}

.offcanvas-collapse {
  &.open {
    @media (max-width: 1199px) {
      right: 0;
      visibility: visible;
    }
  }
}

// Center column on laptop

.center-column {
  @media (max-width: 1366px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 991px) {
    justify-content: flex-start;
  }
}

// Sticky header on scroll

.fixed-me {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  box-shadow: 0px 1px 4px #00000024;
  // background-color: rgba($color: #f9f9f9, $alpha: 1) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.navbar-me {
  transition: padding 0.2s ease !important;
}

// Slick slider customization
.slick-list {
  // padding: 110px 0px !important;
  // .slick-list{

  // }
  padding-top: 110px !important;
  padding-bottom: 45px !important;

  @media (max-width:1199px) {
    padding-top: 60px !important;
  }

  // @media (max-width: 992px) {
  //   padding:0 20% 0 0;
  // }
}




.slick-current {
  .card-wrapper {
    .card {
      background: transparent linear-gradient(146deg, #0090ff 28%, #009750 74%, #006721 100%) 0% 0% no-repeat !important;
      padding: 50px;
      color: #ffffff;
      min-height: 447px;
      box-shadow: 10px 15px 30px #89c5f973 !important;
      // position: absolute;
      // top: -30px;
      transform: translateY(-30px);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      @media (max-width: 767px) {
        transform: translateY(0px);
      }

      &::before {
        position: absolute;
        content: " ";
        // background: url(./assets/images/back.png) no-repeat;
        background: url('../assets/images/back.png') no-repeat;
        height: 290px;
        background-size: 290px;
        width: 290px;
        top: -100px;
        left: -50px;

        @media (max-width: 1199px) {
          display: none;
        }
      }

      .btn-gradient {
        white-space: nowrap;
        // font-size: 26px;
        // padding: 12px 40px !important;
        border-radius: 38px;
        background: #ffffff !important;
        color: #3D4057 !important;
        // @media (max-width:1366px) {
        //   font-size: 18px;
        //   padding: 12px 25px !important;
        // }
      }

      h2,
      h3,
      h6 {
        color: #ffffff;
      }

      .heading-3 {
        font-size: 2.0625rem;
      }

      // p {
      //   margin-bottom: 40px;
      // }
    }
  }

  .card-wrapper-2 {
    // @media (max-width:1199px) {
    //   justify-content: flex-start !important;
    // }
  }
}

.slide {
  &:focus {
    outline: none;
  }

  .card-wrapper {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    // @media (max-width:1199px) {
    //   display: flex;
    //   justify-content: center !important;
    // }
    &:focus {
      outline: none;
    }

    .card {
      &:first-child {
        margin-left: 30px;
      }

      &:last-child {
        margin-right: 30px;
      }
    }
  }

  .card-wrapper-1 {
    // display: flex;
    // justify-content: center;
  }

  .card-wrapper-2 {
    // display: flex;
    // justify-content: flex-end;
  }
}

a:focus {
  outline: none !important;
  box-shadow: none !important;
}

.top-rated-section .slick-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.top-rated-section {
  .slick-slider {
    position: relative;

    .slick-arrow {
      position: absolute;
      top: -50px;
      right: 20px;

    }

    .carousel-control-prev {
      left: unset;
      right: 60px;

      &:hover {
        background-color: map-get($theme-colors, primary) !important;
      }

      img {
        // margin-right: 3px;
        margin-bottom: 1px;
      }
    }

    .carousel-control-next {
      &:hover {
        background-color: map-get($theme-colors, primary) !important;
      }

      img {
        transform: rotate(180deg);
        margin-left: 2px;
        margin-bottom: 1px;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      height: 29px;
      width: 29px;
      background-color: rgba($color: #3D4057, $alpha: 0.5);
      border-radius: 50%;
      opacity: 1 !important;
      top: -70px;

      // @media (max-width: 1366px) {
      //   height: 25px;
      //   width: 25px;
      // }
      // @media (max-width: 767px) {
      //   top: -70;
      // }
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      height: 16px;
      width: 16px;
      // @media (max-width: 1366px) {
      //   background-size: 12px;
      // }
    }
  }
}

.custom-tooltip{
  z-index: 0 !important;
}

.select-box {
  position: relative;

  // margin-right: 10px;
  &::after {
    content: "";
    position: absolute;
    background: url("../assets/images/Icon-ionic-ios-arrow-down.svg") no-repeat;
    opacity: 0.6;
    top: 52% !important;
    transform: translate(-50%, -50%);
    right: 15px;
    height: 8px;
    width: 12px;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    box-shadow: 0px 2px 5px #00000027;
    width: 222px;
    height: 49px;
    -webkit-appearance: none;
    appearance: none;
  }
}

.partner-box {
  .slick-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.arrow-icon {
  height: 20px;
  width: 20px;
  display: block;
  margin: auto;

  img {
    width: 20px;
    height: 20px;
  }
}

.table th,
.table td {
  padding: 0.75rem 1.2rem;
}

.word-break {
  word-break: break-all;
}

.courses-banner-slider {

  .slick-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .slick-slide {
    margin: 0 5px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -5px;
  }

  .slick-slide {

    // max-width: 260px;
    // width: 260px;
    .card {
      width: 260px;
      margin: auto;
      // height: 132px;
    }
  }

  .slide-btn {
    top: 40%;
    opacity: 0.2;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:active {
      opacity: 0.5;
      -webkit-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      left: -30px;

      @media screen and (max-width: 480px) {
        left: -10px;
      }
    }

    &.slick-next {
      right: -17px;

      @media screen and (max-width: 480px) {
        right: 0px;
      }
    }
  }
}

@media only screen and (min-device-width: 567px) and (max-width: 601px) {
  .courses-banner-slider {
    .slide-btn{
      height: 50px;
      width: 50px;
      svg{
        height: 100%;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .courses-banner-slider {
    .slide-btn{
      height: 50px;
      width: 50px;
      svg{
        height: 100%;
      }
    }
  }
}