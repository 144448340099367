h1{
  // @media (max-width:1600px) {
    font-size: 2.1rem;
  // }
  @media (max-width:1024px) {
    font-size: 1.8rem;
  }
}
h2{
  // @media (max-width:1600px) {
    font-size: 2.1rem;
  // }
  @media (max-width:1024px) {
    font-size: 1.8rem;
  }
}
h3{
  // @media (max-width:1600px) {
    // font-size: 24px;
    font-size: 29px;
  // }
}
h4{
  // @media (max-width:1600px) {
    font-size: 1.3rem;
  // }
}
h5{
  // @media (max-width:1600px) {
    font-size: 1rem;
  // }
}
h6{
  // @media (max-width:1600px) {
    font-size: 1.2rem;
  // }
}
.font-extra-light{
  font-weight: 300;
}
.font-light{
  font-weight: 300;
}
.font-medium{
  font-weight: 500;
}
.font-bold{
  font-weight: 600;
}
// .font-bold{
//   font-weight: 700;
// }
.font-medium{
  font-size: 16px;
}
.font-small{
  font-size: 0.875rem !important;
}
.font-smaller{
  font-size: 12px !important;
}
p{
  // font-size: 1.0625rem;
  font-size: 16px;
  letter-spacing: 0.37px;
  // @media (max-width:1200px) {
    
  // }
}
a{
  // @media (max-width:1366px) {
  //   font-weight: 300;
  // }
}
