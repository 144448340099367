// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

$theme-colors:(
    "primary": #07090A,
    "secondary": #A4A3A4,
    "successOne": #347FF0,
    "success": #07090A,
    "light": #ffff,
    "dark": #1A281F,
    "greyColor": #707070,
    "lightGreyColor": #F9F9F9,
    "pinkColor": #FF7285,
    "pinkColor2": #F55A5A,
    "lightDark": #475677,
    "fullDark" :#07090A,
    "darkBlack": #000000
);


$container-max-widths: (
  lg: 960px,
  xl: 1220px
);

$yiq-contrasted-threshold: 170;

$transition: all 0.2s ease 0s;

// $body-bg: map-get($theme-colors,bodyBg);
$body-color: map-get($theme-colors,fullDark);

$font-family-base: 'Poppins', sans-serif;
$headings-font-family: 'Poppins', sans-serif;
$font-size-base: 1rem;

// Style anchor elements.

$link-color: map-get($theme-colors,darkBlack);
$link-hover-decoration:     none;

// headings
$headings-font-weight: 600;
$headings-color: map-get($theme-colors,secondary);

// $h1-font-size: 48px;
$h1-font-size: 2.1rem;
// $h2-font-size: 44px;
$h2-font-size: 1.8rem;
// $h3-font-size: 33px;
$h3-font-size: 1.2rem;
// $h4-font-size: 32px;
$h4-font-size:  1.3rem;
// $h5-font-size: 21px;
$h5-font-size: 1rem;
// $h6-font-size: 24px;
$h6-font-size: 1.2rem;

// buttons
$btn-font-weight: 500;
$btn-padding-y: 16px;
$btn-padding-x: 55px;
$btn-border-radius: 15px;

$btn-padding-y-sm: 9px;
$btn-padding-x-sm: 40px;
$btn-border-radius-sm: 15px;

// navbar
$navbar-padding-x:124px;
$navbar-padding-y:20px;
$navbar-nav-link-padding-x: 22px;
