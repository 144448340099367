.my-schedule-box {
  .my-schedule {
    .card {
      .fc {
        .fc-timegrid-event{
          .notification-dropdown{
            > a{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }
        }
        .fc-timegrid{
          .fc-timegrid-event-harness{
            z-index: 15 !important;
          }
        }
        .fc-daygrid-event {
          z-index: inherit !important;

          .notification-dropdown {
            background: transparent !important;
            border-radius: 4px !important;
            margin-left: 5px !important;

            a {
              width: auto;
              height: auto;
              white-space: normal;
              padding: 0px;
              margin-bottom: 5px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px !important;
              font-family: $font-family-base;
              font-weight: 200;
              z-index: -1 !important;
              padding: 5px;

              ~.dropdown-menu.dropdown-performance {
                padding: 0px !important;
                min-width: 180px;
                @media (max-width:575px) {
                  width: 205px;
                }
                a {
                  display: block;
                  width: 100%;
                  padding: 0px 20px;
                  font-style: initial;
                  text-transform: capitalize;
                  @media (max-width:575px) {
                    word-break: break-all;
                  }
                  &:hover {
                    color: #000000 !important;
                    background: transparent;
                    font-weight: 400;
                    font-style: initial;
                  }

                  &:focus {
                    background: transparent !important;
                  }
                }
              }
            }
          }

        }

        .fc-header-toolbar {
          @media (max-width: 480px) {
            flex-direction: column-reverse;
            align-items: flex-start;
            > div{
              margin: 5px 0px;
            }
          }

          .fc-toolbar-title {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 400;
          }

          .fc-toolbar-chunk {

            display: flex;

            // .fc-week-button {
            //   display: block !important;
            //   border-radius: 0px;
            //   background: #ffffff !important;
            //   color: #475677;
            //   margin-left: 0px !important;
            //   border: 0.5px solid #D7DAE2;
            //   border-left: none !important;
            // }

            .fc-month-button {
              // display: block !important;
              // border-radius: 0px;
              // background: #ffffff;
              // color: #475677;
              // border: 0.5px solid #D7DAE2;
              border-radius: 4px;

              ~button {
                display: none;
              }
            }
          }
        }

        table {
          tr {
            th {
              height: 45px;
              background: #F5F6FA;

              .fc-scrollgrid-sync-inner {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                  text-transform: uppercase;
                  color: #475677;
                  font-size: 16px;
                  font-weight: 300;
                }
              }
            }
          }
            .dropdown-item:active {
              background-color: transparent;
              color : #212529;
            }
        }
      }
    }
  }
}

