.btn-light {
  color: map-get($theme-colors, secondary) !important;
}
.btn-outline-light {
  &:hover {
    color: map-get($theme-colors, secondary) !important;
  }
}
.btn-outline-primary {
  &:hover {
    color: map-get($theme-colors, light) !important;
  }
}
.btn-outline-secondary {
  &:hover {
    color: map-get($theme-colors, light) !important;
  }
}
.btn-outline-success {
  &:hover {
    color: map-get($theme-colors, light) !important;
  }
}
.btn-gradient {
  background:transparent linear-gradient(127deg, #0090FF 27%, #009750 98%, #006721 160%);
  color: #fff !important;
  border: 0 !important;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btn-sm-gradient {
  background: transparent linear-gradient(115deg, #0090FF 34%, #009750 98%, #006721 160%);
  color: #fff !important;
  border: 0 !important;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn-gray-fill {
  background: #3D4057 !important;
  color: #fff !important;
  border: 0 !important;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
